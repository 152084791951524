/* eslint no-use-before-define: 0 */ // --> OFF
// EXPORTAR A EXCEL MIXIN

// require('script-loader!file-saver');
import XLSX from 'xlsx'

export default {
  data () {
    return {
      downloadLoading: false
    }
  },

  methods: {
    exportarXls (xls) {
      this.downloadLoading = true
      import('@/mixins/exportarExcel').then(excel => {
        const tHeader = xls.tHeaders
        const filterVal = xls.filterVal
        const list = xls.data
        const data = this.formatJson(filterVal, list)

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: xls.nomfile,
          autoWidth: true,
          bookType: 'xls'
        })
        this.downloadLoading = false
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    }

  }
}
